import { fastdom, createStylesheet } from "@devowl-wp/web-scoped-css";
import { mapAll, mapIgnore, mapHex, hexToRgb, mapValueSuffix, mapValueSuffixPx, mapValueSuffixPxArray, mapStringToBoolean, mapStringToIsEmpty } from "@devowl-wp/web-scoped-css";
import { SCALING_WHEN_WINDOW_WIDTH_LOWER } from "..";
function createMainStylesheet(customize, _ref) {
  let {
    rule,
    computed,
    variable,
    vars,
    meta,
    className,
    plugin
  } = _ref;
  // Compatibility with `@devowl-wp/web-html-element-interaction-recorder`
  plugin("modifyRule", properties => {
    const {
      pseudos,
      forceSelector
    } = properties;
    const focusVisible = ":focus-visible";
    const focusVisibleClassName = ".wheir-focus-visible";
    if (typeof forceSelector === "string" && (forceSelector === null || forceSelector === void 0 ? void 0 : forceSelector.indexOf(focusVisible)) > -1) {
      properties.forceSelector += `,${forceSelector.replace(new RegExp(focusVisible, "g"), focusVisibleClassName)}`;
    }
    for (const pseudo in pseudos) {
      if (pseudo.indexOf(focusVisible) > -1) {
        pseudos[pseudo.replace(new RegExp(focusVisible, "g"), focusVisibleClassName)] = pseudos[pseudo];
      }
    }
  });
  const {
    pageRequestUuid4
  } = customize;

  // Misc variables
  const isTcf = variable(customize.isTcf);
  const unsetDialogStyles = className();
  rule({
    // Reset `<dialog` styles
    background: "none",
    padding: "0px",
    margin: "0px",
    border: "none",
    maxWidth: "initial",
    maxHeight: "initial",
    position: "fixed",
    outline: "none !important",
    // all: "revert",
    pseudos: {
      "::backdrop": {
        all: "unset"
      }
    },
    // Do not allow to modify props via filterClassName
    forceSelector: `.${unsetDialogStyles}`
  });

  // Make elements only available to screen readers and hide visually
  // See https://www.a11ymatters.com/pattern/checkbox/
  const screenReaderOnlyClass = className();
  rule({
    position: "absolute",
    clip: "rect(1px, 1px, 1px, 1px)",
    padding: "0px",
    border: "0px",
    height: "1px",
    width: "1px",
    overflow: "hidden",
    display: "block",
    // Do not allow to modify props via filterClassName
    forceSelector: `.${screenReaderOnlyClass}`
  });
  const a11yFocusStyle = {
    outline: {
      outline: "black auto 1px !important",
      outlineOffset: "3px !important"
    },
    text: {
      textDecoration: "underline dashed !important",
      textUnderlineOffset: "3px !important"
    }
  };

  // Common styling for focused elements (links)
  rule({
    ...a11yFocusStyle.outline,
    forceSelector: `.${meta.id} :focus-visible, .${meta.id}:focus-visible, .${meta.id} *:has(+ .${screenReaderOnlyClass}:focus-visible)`
  });

  // Create mobile experience view with hacky container queries (use `dispatchResize` on window resize event)
  const isMobileClass = className();
  const hideOnMobileClass = className();
  const [mobile, updateMobile] = vars(customize.mobile, {
    maxHeight: mapValueSuffixPx,
    alignment: mapStringToBoolean(customize.mobile.alignment, ["bottom", "center", "top"])
  });
  const {
    isMobile,
    isMobileWidth,
    update: dispatchResize
  } = computed([mobile.enabled], _ref2 => {
    let [enabled] = _ref2;
    const {
      body: {
        classList
      }
    } = document;
    const overlayElement = document.getElementById(pageRequestUuid4);
    const {
      innerWidth
    } = window;
    const width = (overlayElement === null || overlayElement === void 0 ? void 0 : overlayElement.clientWidth) || innerWidth;
    const isMobileWidth = width <= SCALING_WHEN_WINDOW_WIDTH_LOWER;
    const isMobile = enabled && isMobileWidth;
    if (isMobile) {
      classList.add(isMobileClass);
    } else {
      classList.remove(isMobileClass);
    }
    return {
      isMobile,
      isMobileWidth
    };
  }, "mobile", "raf");
  rule({
    forceSelector: `.${isMobileClass} .${hideOnMobileClass}`,
    display: "none"
  });
  const [{
    x: scaleHorizontal,
    y: scaleVertical
  }] = vars({
    x: `calc(${mobile.scalePercent()} / 100)`,
    y: `calc((${mobile.scalePercent()} + ${mobile.scalePercentVertical()}) / 100)`
  });
  const mapMarginOrPaddingToScalingFactor = padding => {
    const pxs = mapValueSuffixPxArray(padding);
    const createCalc = (px, i) => `calc(${px}*${[0, 2].indexOf(i) > -1 ? scaleVertical() : scaleHorizontal()})`;
    const res = {
      l: pxs,
      m: pxs.map(createCalc)
    };
    for (let i = 0; i < pxs.length; i++) {
      const px = pxs[i];
      res[`l${i}`] = px;
      res[`m${i}`] = createCalc(px, i);
    }
    return res;
  };
  const mapNumberToScalingFactor = n => {
    const px = mapValueSuffixPx(n);
    return {
      l: px,
      m: `calc(${px}*${scaleHorizontal()})`
    };
  };
  const boolLargeOrMobile = (consumer, boolIf, idx) => boolIf(isMobile, consumer(typeof idx === "number" ? `m${idx}` : "m"), consumer(typeof idx === "number" ? `l${idx}` : "l"));

  // Create variables of customize settings
  const [decision, updateDecision] = vars(customize.decision, {
    acceptAll: mapStringToBoolean(customize.decision.acceptAll, ["button", "link", "hide"]),
    acceptEssentials: mapStringToBoolean(customize.decision.acceptAll, ["button", "link", "hide"]),
    acceptIndividual: mapStringToBoolean(customize.decision.acceptAll, ["button", "link", "hide"])
  });
  const [layout, updateLayout] = vars(customize.layout, {
    dialogPosition: mapStringToBoolean(customize.layout.dialogPosition, ["middleCenter"]),
    bannerPosition: mapStringToBoolean(customize.layout.bannerPosition, ["top", "bottom"]),
    borderRadius: mapValueSuffixPx,
    dialogMargin: mapValueSuffixPxArray,
    dialogBorderRadius: mapValueSuffixPx,
    dialogMaxWidth: mapValueSuffixPx,
    overlayBg: hexToRgb,
    overlayBlur: mapValueSuffixPx,
    bannerMaxWidth: mapValueSuffixPx
  });
  const [bodyDesign, updateBodyDesign] = vars({
    // This variables can be unset in free version, so we create defaults
    accordionMargin: [0, 0, 0, 0],
    accordionPadding: [0, 0, 0, 0],
    accordionArrowType: "none",
    accordionArrowColor: "white",
    accordionBg: "white",
    accordionActiveBg: "white",
    accordionHoverBg: "white",
    accordionBorderWidth: 0,
    accordionBorderColor: "white",
    accordionTitleFontSize: 0,
    accordionTitleFontColor: "white",
    accordionTitleFontWeight: "white",
    accordionDescriptionMargin: [0, 0, 0, 0],
    accordionDescriptionFontSize: 0,
    accordionDescriptionFontColor: "white",
    accordionDescriptionFontWeight: "white",
    ...customize.bodyDesign
  }, {
    padding: mapMarginOrPaddingToScalingFactor,
    descriptionFontSize: mapNumberToScalingFactor,
    teachingsFontSize: mapNumberToScalingFactor,
    teachingsSeparatorWidth: mapValueSuffixPx,
    teachingsSeparatorHeight: mapValueSuffixPx,
    dottedGroupsFontSize: mapNumberToScalingFactor,
    acceptAllFontSize: mapNumberToScalingFactor,
    acceptAllPadding: mapMarginOrPaddingToScalingFactor,
    acceptAllBorderWidth: mapNumberToScalingFactor,
    acceptAllTextAlign: mapStringToBoolean(customize.bodyDesign.acceptAllTextAlign, ["center", "right"]),
    acceptEssentialsFontSize: mapNumberToScalingFactor,
    acceptEssentialsPadding: mapMarginOrPaddingToScalingFactor,
    acceptEssentialsBorderWidth: mapNumberToScalingFactor,
    acceptEssentialsTextAlign: mapStringToBoolean(customize.bodyDesign.acceptEssentialsTextAlign, ["center", "right"]),
    acceptIndividualFontSize: mapNumberToScalingFactor,
    acceptIndividualPadding: mapMarginOrPaddingToScalingFactor,
    acceptIndividualBorderWidth: mapNumberToScalingFactor,
    acceptIndividualTextAlign: mapStringToBoolean(customize.bodyDesign.acceptIndividualTextAlign, ["center", "right"]),
    accordionMargin: mapMarginOrPaddingToScalingFactor,
    accordionTitleFontSize: mapNumberToScalingFactor,
    accordionBorderWidth: mapNumberToScalingFactor,
    accordionPadding: mapMarginOrPaddingToScalingFactor,
    accordionDescriptionFontSize: mapNumberToScalingFactor,
    accordionDescriptionMargin: mapMarginOrPaddingToScalingFactor
  });
  const [saveButton, updateSaveButton] = vars(customize.saveButton, {
    type: mapStringToBoolean(customize.saveButton.type, ["button", "link"]),
    fontSize: mapNumberToScalingFactor,
    padding: mapMarginOrPaddingToScalingFactor,
    borderWidth: mapNumberToScalingFactor,
    textAlign: mapStringToBoolean(customize.saveButton.textAlign, ["center", "right"])
  });
  const [design, updateDesign] = vars(customize.design, {
    fontColor: mapHex,
    fontSize: mapNumberToScalingFactor,
    borderWidth: mapNumberToScalingFactor,
    textAlign: mapStringToBoolean(customize.design.textAlign, ["center", "right"]),
    boxShadowBlurRadius: mapValueSuffixPx,
    boxShadowOffsetX: mapValueSuffixPx,
    boxShadowOffsetY: mapValueSuffixPx,
    boxShadowSpreadRadius: mapValueSuffixPx,
    boxShadowColor: mapHex,
    boxShadowColorAlpha: mapValueSuffix("%")
  });
  const [headerDesign, updateHeaderDesign] = vars(customize.headerDesign, {
    fontSize: mapNumberToScalingFactor,
    borderWidth: mapNumberToScalingFactor,
    padding: mapMarginOrPaddingToScalingFactor,
    textAlign: mapStringToBoolean(customize.headerDesign.textAlign, ["center", "right"]),
    logoPosition: mapStringToBoolean(customize.headerDesign.logoPosition, ["left", "right"]),
    logo: mapStringToIsEmpty(false),
    logoMargin: mapMarginOrPaddingToScalingFactor
  });
  const [footerDesign, updateFooterDesign] = vars(customize.footerDesign, {
    borderWidth: mapNumberToScalingFactor,
    padding: mapMarginOrPaddingToScalingFactor,
    fontSize: mapNumberToScalingFactor
  });
  const [texts, updateTexts] = vars(customize.texts, {
    ...mapAll(customize.texts, mapIgnore),
    headline: mapStringToIsEmpty()
  });
  const [individualLayout, updateIndividualLayout] = vars(customize.individualLayout, {
    dialogMaxWidth: mapValueSuffixPx,
    bannerMaxWidth: mapValueSuffixPx
  });
  const [group, updateGroup] = vars(customize.group, {
    headlineFontSize: mapNumberToScalingFactor,
    descriptionFontSize: mapNumberToScalingFactor,
    groupPadding: mapMarginOrPaddingToScalingFactor,
    groupBorderRadius: mapValueSuffixPx,
    groupBorderWidth: mapNumberToScalingFactor,
    groupSpacing: mapNumberToScalingFactor,
    checkboxBorderWidth: mapNumberToScalingFactor
  });

  // Add class to server-side rendered overlay
  const overlayElement = document.getElementById(pageRequestUuid4);
  if (overlayElement) {
    fastdom.mutate(() => {
      overlayElement.className = meta.id;
    });
  }
  const registerWindowResize = () => {
    window.addEventListener("resize", dispatchResize);
    return () => window.removeEventListener("resize", dispatchResize);
  };
  return {
    customize,
    unsetDialogStyles,
    a11yFocusStyle,
    scaleHorizontal,
    scaleVertical,
    isTcf,
    dispatchResize,
    registerWindowResize,
    boolLargeOrMobile,
    isMobile,
    isMobileWidth,
    isMobileClass,
    hideOnMobileClass,
    screenReaderOnlyClass,
    updater: {
      decision: updateDecision,
      layout: updateLayout,
      design: updateDesign,
      bodyDesign: updateBodyDesign,
      headerDesign: updateHeaderDesign,
      footerDesign: updateFooterDesign,
      texts: updateTexts,
      mobile: updateMobile,
      individualLayout: updateIndividualLayout,
      group: updateGroup,
      saveButton: updateSaveButton
    },
    decision,
    layout,
    design,
    bodyDesign,
    headerDesign,
    footerDesign,
    individualLayout,
    group,
    saveButton,
    texts,
    mobile
  };
}
let updateTimeout;
function updateCustomCssStyle(mainStylesheet) {
  let css = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  let id = arguments.length > 2 ? arguments[2] : undefined;
  clearTimeout(updateTimeout);
  updateTimeout = setTimeout(() => {
    const pseudoCss = css.replace(/\.rcb-([A-Za-z0-9_-]+)/g, (m, rcbClassName) => {
      return `.${mainStylesheet[`rcb-${rcbClassName}`] || m.substring(1)}`;
    });
    createStylesheet(pseudoCss, `custom-css-${id}`, true);
  }, 0);
}
const filterClassNameForCustomCss = function (id, forceRcbClassName, customCss) {
  for (var _len = arguments.length, _ref3 = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    _ref3[_key - 3] = arguments[_key];
  }
  let [classNames, scopedClassName, {
    mainElement
  }] = _ref3;
  // The first passed classname should always be the anti-ad-block class
  if (classNames.length) {
    const rcbClassName = `rcb-${classNames[0]}`;
    if (forceRcbClassName) {
      classNames[0] = rcbClassName;
    } else {
      classNames.splice(0, 1);
    }
    mainElement[rcbClassName] = scopedClassName;
    updateCustomCssStyle(mainElement, customCss, id);
  }
};
export { createMainStylesheet, filterClassNameForCustomCss, updateCustomCssStyle };