import { useBanner, useBannerHistorySelect, useBannerStylesheet } from "../../..";
import { Fragment, useState, useEffect } from "react";
import { extendCommonStylesheet } from "@devowl-wp/web-cookie-banner";
const SELECT_NONE = -1;
const BannerHistorySelect = () => {
  const {
    Select
  } = useBannerStylesheet().extend(...extendCommonStylesheet);
  const selectHistoryEntry = useBannerHistorySelect();
  const banner = useBanner();
  const [selectedEntry, setSelectedEntry] = useState();
  const {
    activeAction,
    history,
    i18n: {
      historyLabel,
      historySelectNone
    }
  } = banner;
  useEffect(() => {
    if (activeAction === "history" && history !== null && history !== void 0 && history.length) {
      // Preselect first history entry
      setSelectedEntry(history[0]);
    }
  }, [activeAction, history]);
  useEffect(() => {
    if (selectedEntry) {
      selectHistoryEntry(selectedEntry.context);
    }
  }, [selectedEntry]);

  // History can only contain my data, so we can pick the first entry for the history
  const uuid = selectedEntry === null || selectedEntry === void 0 ? void 0 : selectedEntry.uuid;
  return h(Fragment, null, historyLabel, "\xA0", h(Select, {
    disabled: !(history !== null && history !== void 0 && history.length),
    value: (selectedEntry === null || selectedEntry === void 0 ? void 0 : selectedEntry.id) || SELECT_NONE,
    onChange: e => {
      const value = +e.target.value;
      for (const entry of history) {
        const {
          id
        } = entry;
        if (id === value) {
          setSelectedEntry(entry);
          break;
        }
      }
    }
  }, (history === null || history === void 0 ? void 0 : history.length) > 0 ? history.map(_ref => {
    let {
      id,
      isDoNotTrack,
      isUnblock,
      isForwarded,
      created
    } = _ref;
    return h("option", {
      key: id,
      value: id
    }, new Date(created).toLocaleString(document.documentElement.lang), isDoNotTrack ? " (Do Not Track)" : "", isUnblock ? " (Content Blocker)" : "", isForwarded ? " (Consent Forwarding)" : "");
  }) : h("option", {
    value: SELECT_NONE
  }, historySelectNone)), h("div", {
    style: {
      opacity: 0.5,
      marginTop: 5
    }
  }, "UUID: ", uuid || "-"));
};
export { BannerHistorySelect };