import { useCallback, Fragment } from "react";
import { useBanner } from "../../../contexts";
import { Checkbox } from "../..";
const BannerDottedGroup = _ref => {
  let {
    group: {
      id,
      slug,
      name
    }
  } = _ref;
  const banner = useBanner();
  const {
    decision: {
      groupsFirstView
    },
    design: {
      fontSize
    },
    consent,
    essentialGroup
  } = banner;
  const isPro = process.env.PLUGIN_CTX === "pro";
  const isEssentialGroup = essentialGroup === slug;
  const isChecked = isEssentialGroup || !!consent[id];
  const showCheckbox = isPro && groupsFirstView;
  const handleCheckbox = useCallback(chcked => banner.updateGroupChecked(id, chcked), [banner, id, isChecked]);
  return h(Fragment, null, showCheckbox ? h(Checkbox, {
    onToggle: handleCheckbox,
    isChecked: isChecked,
    isDisabled: isEssentialGroup,
    fontSize: fontSize
  }, name) : h("span", null, h("i", null), h("span", null, name)));
};
export { BannerDottedGroup };