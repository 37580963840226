import _extends from "@babel/runtime/helpers/extends";
import { extendCommonStylesheet } from "@devowl-wp/web-cookie-banner";
import { useState } from "react";
import { useStylesheet } from "../../contexts";
import { PlainAntdIconAsSvg } from "..";
import { useA11yId } from "../../hooks";
const AccordionList = _ref => {
  let {
    children
  } = _ref;
  const {
    AccordionList
  } = useStylesheet().extend(...extendCommonStylesheet);
  return h(AccordionList, null, children);
};
const AccordionItem = _ref2 => {
  let {
    children,
    title,
    icon,
    iconExpanded,
    expandable = true
  } = _ref2;
  const {
    accordionArrow,
    AccordionDescription,
    AccordionItem,
    AccordionButton,
    AccordionTitle,
    accordionItemActive,
    accordionItemDisabled
  } = useStylesheet().extend(...extendCommonStylesheet);
  const [expanded, setExpanded] = useState(false);
  const a11yExpanded = useA11yId();
  return h(AccordionItem, {
    onClick: () => expandable && setExpanded(!expanded),
    className: [expanded && accordionItemActive, !expandable && accordionItemDisabled].filter(Boolean).join(" ")
  }, h(AccordionButton, _extends({}, expandable ? {} : {
    disabled: "disabled"
  }, {
    "aria-expanded": expanded,
    "aria-controls": a11yExpanded,
    href: "#",
    onClick: e => e.preventDefault()
  }), !!icon && h(PlainAntdIconAsSvg, {
    icon: expanded && !!iconExpanded ? iconExpanded : icon,
    className: accordionArrow
  }), h(AccordionTitle, null, title)), h("div", {
    id: a11yExpanded,
    "aria-labelledby": a11yExpanded
  }, expanded && expandable && h(AccordionDescription, null, children)));
};
export { AccordionList, AccordionItem };