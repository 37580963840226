import { CookieConsentManager } from "@devowl-wp/cookie-consent-web-client";
import { getCmpVersion, getOtherOptionsFromWindow } from "../utils";
function getCookieConsentManager() {
  const {
    userConsentCookieName,
    revisionHash,
    setCookiesViaManager,
    groups,
    essentialGroup,
    isGcm
  } = getOtherOptionsFromWindow();
  if (!window.rcbConsentManager) {
    window.rcbConsentManager = new CookieConsentManager({
      decisionCookieName: userConsentCookieName,
      essentialGroupSlug: essentialGroup,
      groups,
      revisionHash,
      supportsCookiesName: "real_cookie_banner-test",
      tagManager: setCookiesViaManager,
      isGcm,
      skipOptIn: function (cookie) {
        const {
          presetId
        } = cookie;
        return ["amazon-associates-widget"].indexOf(presetId) > -1;
      },
      cmpId: process.env.TCF_CMP_ID,
      cmpVersion: getCmpVersion("major"),
      tcfCookieName: `${userConsentCookieName}-tcf`,
      gcmCookieName: `${userConsentCookieName}-gcm`
    });
  }
  return window.rcbConsentManager;
}
export { getCookieConsentManager };