import { AccordionItem, AccordionList } from "../../common";
import { MinusOutlined } from "@ant-design/icons-svg";
import { findUsedGcmConsentTypes } from "@devowl-wp/cookie-consent-web-client";
import { useBannerOrBlocker } from "../../../hooks";
const GcmConsentTypesList = _ref => {
  let {
    services
  } = _ref;
  const usedPurposes = findUsedGcmConsentTypes(services);
  const {
    i18n: {
      gcm: {
        purposes: i18nPurposes
      }
    }
  } = useBannerOrBlocker();
  return h(AccordionList, null, usedPurposes.map(purpose => h(AccordionItem, {
    key: purpose,
    title: i18nPurposes[purpose],
    icon: MinusOutlined,
    expandable: false
  })));
};
export { GcmConsentTypesList };