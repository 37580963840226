import _extends from "@babel/runtime/helpers/extends";
import { useBanner, BannerDottedGroupList, BannerGroupList, BannerTcfGroupList, BannerGcmGroupList, BannerButtonList, BannerHistorySelect, useCommonTeachings, Group } from "../../..";
import { Fragment, useMemo } from "react";
import { extendBannerBodyStylesheet } from "@devowl-wp/web-cookie-banner";
import { useBannerStylesheet, useGroupItems } from "../../../hooks";
import { findUsedGcmConsentTypes } from "@devowl-wp/cookie-consent-web-client";
import { yieldLazyLoad } from "@devowl-wp/react-utils";
const BodyDescription = yieldLazyLoad(import( /* webpackMode: "eager" */"../../common/body/description").then(_ref => {
  let {
    BodyDescription
  } = _ref;
  return BodyDescription;
}), "BodyDescription");
const BANNER_GROUP_NON_STANDARD_STYLE = {
  margin: "20px 0 10px 0"
};
const BannerBody = _ref2 => {
  let {
    leftSideContainerRef,
    rightSideContainerRef
  } = _ref2;
  const {
    a11yIds,
    Container,
    RightSide,
    LeftSide
  } = useBannerStylesheet().extend(...extendBannerBodyStylesheet);
  const banner = useBanner();
  const {
    tcf,
    isGcm,
    individualPrivacyOpen,
    activeAction,
    individualTexts: {
      postamble
    },
    i18n: {
      nonStandard,
      nonStandardDesc
    }
  } = banner;
  const services = useGroupItems();
  const teachings = useCommonTeachings({
    services
  });
  const hasUsedGcmConsentTypes = isGcm && findUsedGcmConsentTypes(services).length > 0;
  const hasMoreThanOneStandard = useMemo(() => !!(process.env.IS_TCF === "1" && tcf || process.env.PLUGIN_CTX === "pro" && hasUsedGcmConsentTypes), [tcf, hasUsedGcmConsentTypes]);
  const leftSideContainer = h(LeftSide, {
    ref: leftSideContainerRef,
    key: "leftSide"
  }, h(BodyDescription, _extends({
    id: a11yIds.description
  }, teachings), activeAction === "history" && h(BannerHistorySelect, null)), individualPrivacyOpen ? h(Fragment, null, hasMoreThanOneStandard && h(Group, {
    headline: nonStandard,
    style: BANNER_GROUP_NON_STANDARD_STYLE,
    borderless: true
  }, nonStandardDesc), h(BannerGroupList, null), process.env.IS_TCF === "1" && tcf && h(BannerTcfGroupList, null), process.env.PLUGIN_CTX === "pro" && hasUsedGcmConsentTypes && h(BannerGcmGroupList, null)) : h(BannerDottedGroupList, null), !!postamble && individualPrivacyOpen && h(BodyDescription, {
    teachings: [postamble],
    isPostamble: true
  }));
  const rightSideContainer = activeAction === "history" ? h("div", {
    ref: rightSideContainerRef
  }) : h(RightSide, {
    ref: rightSideContainerRef,
    key: "rightSide"
  }, h(BannerButtonList, null));
  return h(Container, null, h("div", null, individualPrivacyOpen ? [rightSideContainer, leftSideContainer] : [leftSideContainer, rightSideContainer]));
};
export { BannerBody, BANNER_GROUP_NON_STANDARD_STYLE };