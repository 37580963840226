import { mapValueSuffixPx } from "@devowl-wp/web-scoped-css";
/**
 * Common styles including selects, buttons, switch, ...
 */
const extendCommonStylesheet = [Symbol("extendCommonStylesheet"), (_ref, _ref2) => {
  let {
    control,
    className,
    rule,
    boolIf,
    jsx
  } = _ref;
  let {
    a11yFocusStyle,
    boolLargeOrMobile,
    bodyDesign,
    design,
    group,
    layout,
    screenReaderOnlyClass,
    scaleHorizontal,
    isMobile
  } = _ref2;
  const [Link] = jsx("a", {
    color: group.linkColor(),
    textDecoration: design.linkTextDecoration(),
    pseudos: {
      ":hover": {
        color: group.linkHoverColor(),
        // Can be overwritten by themes
        textDecoration: design.linkTextDecoration()
      }
    }
  });
  const [Label] = jsx("label", {
    all: "unset"
  });
  const [checkboxStyle, checkboxClass] = control({
    fontSize: 15
  }, {
    fontSize: mapValueSuffixPx
  }, _ref3 => {
    let {
      fontSize
    } = _ref3;
    const borderWidth = boolLargeOrMobile(group.checkboxBorderWidth, boolIf);
    const [, checkbox] = rule({
      classNames: ["checkbox", screenReaderOnlyClass],
      pseudos: {
        "+div": {
          aspectRatio: "1/1",
          height: `calc((${fontSize()} + ${borderWidth} * 2 + 6px) * ${boolIf(isMobile, scaleHorizontal(), "1")})`,
          boxSizing: "border-box",
          display: "inline-block",
          marginRight: "10px",
          lineHeight: 0,
          verticalAlign: "middle",
          padding: "3px",
          borderRadius: layout.borderRadius(),
          cursor: "pointer",
          borderStyle: "solid",
          borderWidth: borderWidth,
          backgroundColor: group.checkboxBg(),
          color: group.checkboxBg(),
          borderColor: group.checkboxBorderColor()
        },
        ":checked+div": {
          backgroundColor: group.checkboxActiveBg(),
          color: group.checkboxActiveColor(),
          borderColor: group.checkboxActiveBorderColor()
        },
        "[disabled]+div": {
          cursor: "not-allowed",
          opacity: "0.5"
        },
        ":focus-visible+div": a11yFocusStyle.outline,
        // Title right to the checkbox
        "+div+span": {
          verticalAlign: "middle",
          cursor: "pointer"
        },
        ":focus-visible+div+span>span:first-of-type": a11yFocusStyle.text
      }
    });
    return checkbox;
  });
  const {
    fontColor
  } = design;
  const [Select] = jsx("select", {
    background: "transparent",
    border: 0,
    fontSize: boolIf(bodyDesign.descriptionInheritFontSize, boolLargeOrMobile(design.fontSize, boolIf), boolLargeOrMobile(bodyDesign.descriptionFontSize, boolIf)),
    color: fontColor("hex"),
    borderBottom: `1px solid rgba(${fontColor("r")} ${fontColor("g")} ${fontColor("b")} / 50%)`,
    pseudos: {
      ">option": {
        background: design.bg()
      }
    }
  });
  const [ButtonGroup] = jsx("fieldset", {
    classNames: "group-button",
    all: "unset",
    pseudos: {
      ">label": {
        all: "unset"
      }
    }
  });
  const [, buttonGroupItem] = rule({
    classNames: ["group-button-item", screenReaderOnlyClass],
    pseudos: {
      "+span": {
        padding: "5px 10px",
        color: group.linkColor(),
        borderRadius: layout.borderRadius(),
        textDecoration: design.linkTextDecoration(),
        opacity: 0.8,
        cursor: "pointer",
        borderWidth: boolLargeOrMobile(bodyDesign.acceptAllBorderWidth, boolIf),
        borderStyle: "solid",
        borderColor: "transparent"
      },
      ":checked+span": {
        opacity: 1,
        cursor: "initial",
        textDecoration: "initial",
        background: bodyDesign.acceptAllBg(),
        color: bodyDesign.acceptAllFontColor(),
        borderColor: bodyDesign.acceptAllBorderColor()
      },
      [`:not(:checked)+span:hover,:focus-visible+span`]: {
        opacity: 1,
        // Can be overwritten by themes
        textDecoration: design.linkTextDecoration()
      },
      ":focus-visible+span": a11yFocusStyle.outline
    }
  });
  const accordionBorderWidth = boolLargeOrMobile(bodyDesign.accordionBorderWidth, boolIf);
  const accordionFontSize = boolLargeOrMobile(bodyDesign.accordionTitleFontSize, boolIf);
  const [AccordionList] = jsx("div", {
    classNames: "accordions",
    margin: boolLargeOrMobile(bodyDesign.accordionMargin, boolIf),
    textAlign: "left",
    lineHeight: "1.5",
    pseudos: {
      ">div": {
        borderWidth: "0px",
        borderTopWidth: accordionBorderWidth,
        borderStyle: "solid",
        borderColor: bodyDesign.accordionBorderColor()
      },
      ">div:last-of-type": {
        borderBottomWidth: accordionBorderWidth
      },
      "+p": {
        marginTop: "15px"
      }
    }
  });
  const accordionItemActive = className();
  const accordionItemDisabled = className();
  const [AccordionItem] = jsx("div", {
    classNames: "accordion-item",
    cursor: "pointer",
    padding: bodyDesign.accordionPadding("l"),
    background: bodyDesign.accordionBg(),
    pseudos: {
      [`.${accordionItemActive},:has(>a:focus-visible)`]: {
        background: bodyDesign.accordionActiveBg()
      },
      [`:hover:not(.${accordionItemActive},.${accordionItemDisabled})`]: {
        background: bodyDesign.accordionHoverBg()
      },
      ">a": {
        display: "flex",
        alignItems: "center"
      },
      [`.${accordionItemDisabled}`]: {
        cursor: "initial"
      }
    }
  });
  const [AccordionButton] = jsx("a", {
    classNames: "accordion-button",
    all: "unset"
  });
  const [, accordionArrow] = rule({
    classNames: "accordion-arrow",
    width: accordionFontSize,
    height: accordionFontSize,
    flex: `0 0 ${accordionFontSize}`,
    lineHeight: accordionFontSize,
    float: "left",
    marginRight: "10px",
    color: bodyDesign.accordionArrowColor()
  });
  const [AccordionTitle] = jsx("div", {
    classNames: "accordion-title",
    fontSize: accordionFontSize,
    color: bodyDesign.accordionTitleFontColor(),
    fontWeight: bodyDesign.accordionTitleFontWeight()
  });
  const [AccordionDescription] = jsx("div", {
    classNames: "accordion-description",
    fontSize: boolLargeOrMobile(bodyDesign.accordionDescriptionFontSize, boolIf),
    color: bodyDesign.accordionDescriptionFontColor(),
    fontWeight: bodyDesign.accordionDescriptionFontWeight(),
    margin: boolLargeOrMobile(bodyDesign.accordionDescriptionMargin, boolIf)
  });
  return {
    checkbox: {
      style: checkboxStyle,
      className: checkboxClass
    },
    Link,
    Label,
    Select,
    ButtonGroup,
    buttonGroupItem,
    AccordionList,
    AccordionItem,
    AccordionButton,
    AccordionTitle,
    AccordionDescription,
    accordionArrow,
    accordionItemActive,
    accordionItemDisabled
  };
}];
export { extendCommonStylesheet };