function findUsedGcmConsentTypes(services) {
  return [...new Set(services.map(_ref => {
    let {
      googleConsentModeConsentTypes
    } = _ref;
    return (
      // Create copy when it comes as `ObservableArray` (mobx)
      [...googleConsentModeConsentTypes]
    );
  }).flat())];
}
export { findUsedGcmConsentTypes };