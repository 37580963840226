const extendTeachingStylesheet = [Symbol("extendTeachingStylesheet"), (_ref, _ref2) => {
  let {
    jsx,
    boolIf
  } = _ref;
  let {
    individualLayout,
    design,
    bodyDesign,
    boolLargeOrMobile,
    layout
  } = _ref2;
  const [Teaching, teachingClassName] = jsx("p", {
    classNames: "teaching",
    all: "unset",
    display: "block",
    marginTop: "7px",
    textAlign: boolIf(bodyDesign.teachingsInheritTextAlign, "inherit", bodyDesign.teachingsTextAlign()),
    color: boolIf(bodyDesign.teachingsInheritFontColor, "inherit", bodyDesign.teachingsFontColor()),
    fontSize: boolIf(bodyDesign.teachingsInheritFontSize, "inherit", boolLargeOrMobile(bodyDesign.teachingsFontSize, boolIf))
  });
  const [DescriptionContainer] = jsx("div", {
    classNames: "description",
    lineHeight: 1.5,
    textAlign: individualLayout.descriptionTextAlign(),
    fontSize: boolIf(bodyDesign.descriptionInheritFontSize, boolLargeOrMobile(design.fontSize, boolIf), boolLargeOrMobile(bodyDesign.descriptionFontSize, boolIf)),
    pseudos: {
      [`> p:not(${teachingClassName})`]: {
        all: "unset"
      },
      " a:not([aria-expanded])": {
        color: "inherit",
        textDecoration: "underline"
      },
      " a:not([aria-expanded]):hover": {
        // Can be overwritten by themes
        textDecoration: "none"
      }
    }
  });
  const [TeachingSeparator] = jsx("div", {
    classNames: "teaching-separator",
    display: boolIf(bodyDesign.teachingsSeparatorActive, "block", "none"),
    pseudos: {
      ":after": {
        content: "''",
        marginTop: "7px",
        display: "inline-block",
        maxWidth: "100%",
        borderRadius: layout.borderRadius(),
        width: bodyDesign.teachingsSeparatorWidth(),
        height: bodyDesign.teachingsSeparatorHeight(),
        background: bodyDesign.teachingsSeparatorColor()
      }
    }
  }, {
    "aria-hidden": true
  });
  return {
    DescriptionContainer,
    Teaching,
    TeachingSeparator
  };
}];
export { extendTeachingStylesheet };