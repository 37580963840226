import _extends from "@babel/runtime/helpers/extends";
import { useBannerOrBlocker } from "../../../hooks";
import { Checkbox } from "../..";
import { CookiePropertyList } from ".";
import { useStylesheet } from "../../../contexts";
import { extendCommonGroupsStylesheet } from "@devowl-wp/web-cookie-banner";
const Cookie = _ref => {
  let {
    cookie,
    checked,
    disabled,
    onToggle,
    propertyListProps = {}
  } = _ref;
  const {
    Cookie
  } = useStylesheet().extend(...extendCommonGroupsStylesheet);
  const {
    name
  } = cookie;
  const {
    group: {
      descriptionFontSize
    }
  } = useBannerOrBlocker();
  return h(Cookie, null, h(Checkbox, {
    isChecked: checked,
    isDisabled: disabled,
    fontSize: descriptionFontSize,
    onToggle: onToggle
  }, h("strong", null, name)), h(CookiePropertyList, _extends({
    cookie: cookie
  }, propertyListProps, {
    isDisabled: !checked
  })));
};
export { Cookie };