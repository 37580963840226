import { useBannerOrBlocker } from "../useBannerOrBlocker";
function useGroupItems(map) {
  const {
    groups
  } = useBannerOrBlocker();
  let result = groups.map(_ref => {
    let {
      items
    } = _ref;
    return [...items];
  }).flat();
  if (map) {
    result = result.map(map).filter(Boolean);
  }
  return result;
}
export { useGroupItems };