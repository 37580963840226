import { useImmutableContext, useImmutableContextProvider } from "../context";
const SUSPSENSE_LOADED_CONTEXT_SYMBOL = Symbol();
const useSuspenseLoaded = () => useImmutableContext(SUSPSENSE_LOADED_CONTEXT_SYMBOL);

/**
 * With the power of `SuspenseLoadedContext` we could "wait" for a list of suspended components
 * to be mounted to the DOM. This allows us to e.g. avoid CLS and render the cookie banner when
 * all suspended components are visible.
 *
 * @param throttle Pass a function which takes a callback. The `completed: true` statement gets updated within
 *                 this callback. You could use e.g. `fastdom.mutate()` or `window.requestAnimationFrame` for this.
 */
function useSuspendedComponentsMounted(names, throttle) {
  return useImmutableContextProvider(SUSPSENSE_LOADED_CONTEXT_SYMBOL, {
    completed: false,
    loaded: []
  }, {}, {
    refActions: {
      onMounted: (_ref, name) => {
        let {
          completed,
          loaded,
          set
        } = _ref;
        loaded.push(name);
        if (names.every(i => loaded.indexOf(i) > -1) && !completed) {
          const setTrue = () => set({
            completed: true
          });
          if (throttle) {
            throttle(setTrue);
          } else {
            setTrue();
          }
        }
      }
    }
  });
}
export { useSuspendedComponentsMounted, useSuspenseLoaded };