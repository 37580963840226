import { Checkbox } from "../../common";
import { useBannerOrBlocker, useBannerStylesheet } from "../../../hooks";
import { extendCommonGroupsStylesheet } from "@devowl-wp/web-cookie-banner";
const GcmConsentType = _ref => {
  let {
    type,
    isDisabled,
    isBold
  } = _ref;
  const {
    Cookie
  } = useBannerStylesheet().extend(...extendCommonGroupsStylesheet);
  const {
    gcmConsent,
    updateGcmConsentTypeChecked,
    group: {
      descriptionFontSize
    },
    i18n: {
      gcm: {
        purposes: {
          [type]: purposeText
        }
      }
    }
  } = useBannerOrBlocker();
  return h(Cookie, null, h(Checkbox, {
    isChecked: gcmConsent.indexOf(type) > -1,
    isDisabled: isDisabled,
    fontSize: descriptionFontSize,
    onToggle: state => updateGcmConsentTypeChecked(type, state)
  }, h("span", {
    style: {
      fontWeight: isBold ? "bold" : undefined
    }
  }, purposeText)));
};
export { GcmConsentType };