import { putHtmlCodeToDom } from "@devowl-wp/headless-content-unblocker";
import { getServiceSelection } from "../decision";

/**
 * Opt-in and opt-out to a set of services for a given data manager like GTM / MTM.
 */
async function applyOptInOutManager(options, dataLayer, isManagerOptOut) {
  const waitPromises = [];

  /* onlypro:start */
  const {
    isManagerActive,
    iterateServices
  } = getServiceSelection(options);

  // When there is a manager, let's push all opt-in and opt-out events (parallel)
  if (dataLayer) {
    // Only dispatch the events when Google Tag Manager compatibility is explicitely activated
    // Opt-in events
    iterateServices((group, _ref, isOptIn, _ref2) => {
      let {
        tagManagerOptInEventName: event
      } = _ref;
      let {
        features
      } = _ref2;
      if (isOptIn && event && features.events) {
        dataLayer.push({
          event
        });
      }
    });

    // Wait x seconds and apply opt-out events (should we add this to `waitPromises`?! I do not think so)
    setTimeout(() => iterateServices((group, _ref3, isOptIn, _ref4) => {
      let {
        tagManagerOptOutEventName: event
      } = _ref3;
      let {
        features
      } = _ref4;
      if (!isOptIn && event && features.events) {
        dataLayer.push({
          event
        });
      }
    }), 1000);
  } else if (isManagerActive && isManagerOptOut) {
    // The manager got opt out, let's execute scripts that are explicitly only for this case (sequentially)
    await iterateServices(async (group, _ref5, isOptIn) => {
      let {
        codeDynamics,
        codeOptIn,
        executeCodeOptInWhenNoTagManagerConsentIsGiven
      } = _ref5;
      if (isOptIn && executeCodeOptInWhenNoTagManagerConsentIsGiven) {
        waitPromises.push(putHtmlCodeToDom(codeOptIn, codeDynamics));
      }
    });
  }
  /* onlypro:false */

  return {
    ready: Promise.all(waitPromises)
  };
}
export { applyOptInOutManager };