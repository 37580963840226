import { getUserDecision } from ".";
import { getManagerDependingVariables } from "../apply";
/**
 * Read the selected groups + services depending on the passed type.
 */
function getServiceSelection(_ref) {
  let {
    decisionCookieName,
    tagManager,
    isGcm,
    groups,
    essentialGroupSlug,
    type
  } = _ref;
  let selectedGroups;
  const {
    useManager
  } = getManagerDependingVariables(tagManager, {
    isGcm,
    presetId: ""
  });
  const [essentialGroupObj] = groups.filter(_ref2 => {
    let {
      slug
    } = _ref2;
    return slug === essentialGroupSlug;
  });
  const consentEssentials = {
    [essentialGroupObj.id]: essentialGroupObj.items.map(_ref3 => {
      let {
        id
      } = _ref3;
      return id;
    })
  };

  // Read current consent
  if (type === "consent") {
    const decision = getUserDecision(decisionCookieName);
    if (decision !== false) {
      selectedGroups = decision.consent;
    } else {
      console.warn("Something went wrong while reading the cookie, fallback to essentials only...");
      selectedGroups = consentEssentials;
    }
  }
  if (type === "essentials") {
    selectedGroups = consentEssentials;
  }
  return {
    isManagerActive: useManager !== "none",
    selectedGroups,
    iterateServices: async function (callback) {
      for (const group of groups) {
        for (const service of group.items) {
          var _selectedGroups$group;
          const isOptIn = type === "all" || ((_selectedGroups$group = selectedGroups[group.id]) === null || _selectedGroups$group === void 0 ? void 0 : _selectedGroups$group.indexOf(service.id)) > -1;
          const useTagManager = getManagerDependingVariables(tagManager, {
            presetId: service.presetId,
            isGcm
          });
          await callback(group, service, isOptIn, useTagManager);
        }
      }
    }
  };
}
export { getServiceSelection };