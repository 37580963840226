import { Suspense, useMemo } from "react";
import { getOptionsFromWindow, getOtherOptionsFromWindow } from "../utils";
import { useBannerPreDecisionGateway, useTcf } from "../hooks";
import { applyConsent, getCookieConsentManager } from "../others";
import { useBannerConsentListener, useBannerProvider, useStateContextCallbacks } from "@devowl-wp/react-cookie-banner";
import { useBannerToggleOverlay, useBannerStateContextCallbacks } from "@devowl-wp/react-cookie-banner";
import { locationRestConsentGet } from "../wp-api";
import { commonRequest } from "@devowl-wp/utils";
import { BannerProductionNotice } from ".";
import { Recorder } from "@devowl-wp/web-html-element-interaction-recorder";
import { getUserGcmDecision } from "@devowl-wp/cookie-consent-web-client";
import { yieldLazyLoad } from "@devowl-wp/react-utils";
const Banner = yieldLazyLoad(import( /* webpackMode: "eager" */"@devowl-wp/react-cookie-banner/src/components/banner/banner").then(_ref => {
  let {
    Banner
  } = _ref;
  return Banner;
}));

/**
 * Simply connects a context store to the banner itself. On the website itself
 * it should not rely
 */
const WebsiteBanner = _ref2 => {
  let {
    poweredLink
  } = _ref2;
  const {
    customizeValuesBanner,
    pageRequestUuid4,
    territorialLegalBasis,
    consentForwardingExternalHosts,
    essentialGroup,
    isTcf,
    isDataProcessingInUnsafeCountries,
    dataProcessingInUnsafeCountriesSafeCountries,
    iso3166OneAlpha2,
    isAgeNotice,
    ageNoticeAgeLimit,
    isListServicesNotice,
    isGcmListPurposes,
    isGcm,
    tcf,
    tcfMetadata,
    groups,
    languageSwitcher,
    bannerLinks,
    websiteOperator,
    userConsentCookieName,
    bannerDesignVersion,
    bannerI18n,
    isPro,
    isLicensed,
    isDevLicense,
    affiliate,
    isCurrentlyInTranslationEditorPreview
  } = getOtherOptionsFromWindow();
  const manager = getCookieConsentManager();
  const userDecision = manager.getUserDecision();
  const gcmConsent = getUserGcmDecision(manager.getOption("gcmCookieName"));

  // TCF compatibility
  const tcfObject = useTcf(isTcf, tcf, tcfMetadata);

  // Server-side rendered overlay handler
  const overlay = document.getElementById(pageRequestUuid4);
  const recorder = useMemo(() => new Recorder(overlay), []);
  const toggleOverlay = useBannerToggleOverlay(overlay);
  const modifiers = useStateContextCallbacks();
  const modifiersBanner = useBannerStateContextCallbacks({
    toggleOverlay
  });
  const [BannerContextProvider, bannerContextValue] = useBannerProvider({
    ...customizeValuesBanner,
    recorder,
    productionNotice: h(BannerProductionNotice, {
      isPro: isPro,
      isLicensed: isLicensed,
      isDevLicense: isDevLicense,
      i18n: bannerI18n
    }),
    pageRequestUuid4,
    territorialLegalBasis,
    consentForwardingExternalHosts,
    isTcf,
    isDataProcessingInUnsafeCountries,
    dataProcessingInUnsafeCountriesSafeCountries,
    iso3166OneAlpha2,
    isAgeNotice,
    ageNoticeAgeLimit,
    isListServicesNotice,
    isGcm,
    isGcmListPurposes,
    gcmConsent,
    tcf: tcfObject,
    tcfFilterBy: "legInt",
    groups,
    languageSwitcher,
    links: bannerLinks,
    websiteOperator,
    essentialGroup,
    poweredLink,
    visible: false,
    animationVisible: true,
    skipOverlay: true,
    previewCheckboxActiveState: false,
    individualPrivacyOpen: false,
    designVersion: bannerDesignVersion,
    i18n: bannerI18n,
    keepVariablesInTexts: isCurrentlyInTranslationEditorPreview,
    affiliate,
    consent: {
      ...(userDecision === false ? {} : userDecision.consent),
      // Always override essential cookies
      ...getCookieConsentManager().getDefaultDecision(userDecision === false)
    },
    onApplyConsent: applyConsent,
    didGroupFirstChange: false
  }, {
    ...modifiers,
    ...modifiersBanner,
    fetchHistory: async () => {
      const {
        restNamespace,
        restRoot,
        restQuery,
        restNonce,
        restPathObfuscateOffset
      } = getOptionsFromWindow();
      return await commonRequest({
        location: locationRestConsentGet,
        options: {
          restNamespace,
          restRoot,
          restQuery,
          restNonce,
          restPathObfuscateOffset
        },
        cookieValueAsParam: [userConsentCookieName],
        sendRestNonce: false
      });
    },
    onLanguageSwitch: (_, _ref3) => {
      let {
        url
      } = _ref3;
      window.location.href = url;
    }
  });
  useBannerPreDecisionGateway(bannerContextValue, toggleOverlay);
  useBannerConsentListener(bannerContextValue, userConsentCookieName);
  return h(BannerContextProvider, {
    value: bannerContextValue
  }, h(Suspense, {
    fallback: null
  }, h(Banner, null)));
};
export { WebsiteBanner };