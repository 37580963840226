import { getUserDecision, OPT_IN_ALL_EVENT } from "@devowl-wp/cookie-consent-web-client";
import { useEffect } from "react";
/**
 * Listen to consents of `@devowl-wp/cookie-consent-web-client` as they can be set without
 * UI through our React components (e.g. Georestriction or custom predecision gateway). We need to update
 * those changes to the consent within our React state so "Change privacy settings" works as expected.
 */
function useBannerConsentListener(contextValue, userConsentCookieName) {
  useEffect(() => {
    const listener = () => {
      const consent = getUserDecision(userConsentCookieName);
      consent && contextValue.set({
        consent: consent.consent
      });
    };
    document.addEventListener(OPT_IN_ALL_EVENT, listener);
    return () => {
      document.removeEventListener(OPT_IN_ALL_EVENT, listener);
    };
  }, []);
}
export { useBannerConsentListener };