import { apply } from ".";
import { getDefaultDecision, getUserDecision } from "..";
/**
 * Main class to manage cookie consents within your application.
 */
class CookieConsentManager {
  constructor(options) {
    this.options = void 0;
    this.options = options;
  }
  applyCookies(options) {
    return apply({
      ...options,
      ...this.options
    });
  }
  getUserDecision(onlyUptoDate) {
    const decision = getUserDecision(this.getOption("decisionCookieName"));
    return onlyUptoDate === true ? decision ? decision.revision === this.getOption("revisionHash") ? decision : false : false : decision;
  }
  getDefaultDecision() {
    let respectLegitimateInterests = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    return getDefaultDecision(this.options, respectLegitimateInterests);
  }
  getOption(name) {
    return this.options[name];
  }
  getOptions() {
    return this.options;
  }
}
export { CookieConsentManager };